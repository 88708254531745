export const heading = {
  uk: "Контакти",
  en: "Contacts",
};

export const location = [
  {
    uk: "Кімната 108,",
    en: "Room 108,",
  },
  {
    uk: "1 поверх,",
    en: "1st floor,",
  },
  {
    uk: "вул. Шевченка 28,",
    en: "28 Shevchenka str.,",
  },
  {
    uk: "м. Чернівці, Україна",
    en: "Chernivtsi, Ukraine",
  },
];

export const phoneNuner = "+38 (066) 743 11 37";

export const showEmailButtonText = {
  uk: "Показати email",
  en: "Show email",
};
